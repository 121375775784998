import { useCallback, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  useEffect(() => {
    if (!page) {
      setSearchParams({ page: '1' });
    }
  }, [page, setSearchParams]);

  const setPage = useCallback(
    (newPage: number) => setSearchParams({ page: String(newPage) }),
    [setSearchParams],
  );

  return { page: page ?? '1', setPage } as const;
};
