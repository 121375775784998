import { fromWattToKiloWatt, Money } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow.svg';
import AtIcon from '@/assets/icons/at.svg?react';
import BarcodeIcon from '@/assets/icons/barcode.svg?react';
import GeolocationIcon from '@/assets/icons/geolocation.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import LightningIcon from '@/assets/icons/lightning.svg?react';
import { PageLayout } from '@/common/root/page-layout';
import { ConnectorIcon } from '@/components/connector-icon';
import { LocationMarker } from '@/components/leaflet/location-marker';
import { MapComponent } from '@/components/leaflet/map';
import { Widget } from '@/components/widget';
import { getChargeSiteLatLng } from '@/pages/charge-site/utils/charge-site.utils';
import { Badge } from '@/shadcn/ui/badge';
import { TableRow } from '@/shadcn/ui/table';

import { useConnectorData } from '../hooks/use-connector.query';
import { ConnectorPageSkeleton } from './connector-page-skeleton';
import { ConnectorSettingsMenu } from './connector-settings-menu';

export const ConnectorPage = () => {
  const { t } = useTranslation();
  const { connectorId } = useParams();
  const { data: connectorData, isLoading } = useConnectorData(Number(connectorId));

  if (isLoading || !connectorData) {
    return <ConnectorPageSkeleton />;
  }

  const chargeSiteLatLng = getChargeSiteLatLng(connectorData.chargeSite);

  const rateMoney = Money.fromPair(
    connectorData.tariff.ratePerWh ?? 0,
    connectorData.tariff.currency,
  );

  return (
    <PageLayout
      title={t('CONNECTOR_PAGE.PAGE_NAME', { readableId: connectorData.readableId })}
      backUrl={`/chargers/${connectorData.chargePointId}`}
      rightSlot={
        connectorData.isArchived ? (
          <Badge variant="ARCHIVED" />
        ) : (
          <ConnectorSettingsMenu connector={connectorData} />
        )
      }
    >
      <div className="flex mb-4">
        <div className="w-1/2 flex-col gap-4">
          <div className="flex gap-4">
            <Widget
              fullWidth
              title={t('CONNECTOR_PAGE.CONNECTOR_READABLE_ID')}
              content={connectorData.readableId}
              contentProps={{ size: '5xl' }}
              reactIcon={<AtIcon />}
            />
            <Widget
              fullWidth
              title={t('CONNECTOR_PAGE.CONNECTOR_UNIQ_ID')}
              content={connectorData.uniqId}
              reactIcon={<BarcodeIcon />}
            />
          </div>

          <div className="flex mt-4 gap-4">
            <Widget
              title={t('CONNECTOR_PAGE.CHARGE_SITE')}
              content={connectorData.chargeSite.title}
              fullWidth
              reactIcon={<HomeIcon />}
            />
            <Widget
              title={t('COORDINATES')}
              content={
                <div>
                  {chargeSiteLatLng.lat}
                  <br />
                  {chargeSiteLatLng.lng}
                </div>
              }
              fullWidth
              reactIcon={<GeolocationIcon />}
            />
          </div>
          <div className="flex mt-4 gap-4">
            <Widget
              title={t('CONNECTOR_PAGE.CONNECTOR_TYPE')}
              content={connectorData.typeDescription}
              fullWidth
              reactIcon={
                <ConnectorIcon width={48} height={48} connectorType={connectorData.type} />
              }
            />
            <Widget
              title={t('CONNECTOR_PAGE.CONNECTOR_MAX_POWER')}
              content={fromWattToKiloWatt(connectorData.powerWt)}
              contentProps={{ size: '5xl' }}
              fullWidth
              reactIcon={<LightningIcon />}
            />
          </div>
        </div>
        <div className="w-1/2 ml-4 z-0">
          <div className="h-full mb-4 ">
            <MapComponent center={chargeSiteLatLng}>
              <LocationMarker
                position={chargeSiteLatLng}
                isSelected
                tooltipContent={connectorData.chargeSite.title}
              />
            </MapComponent>
          </div>
        </div>
      </div>
      <TableRow className="cursor-pointer bg-background-muted rounded-md mb-4 transition-colors hover:bg-muted/50">
        <div className="p-2 pl-6 min-w-64">
          <p className="font-medium text-lg leading-7">
            {t('CONNECTOR_PAGE.TARIFF')}: {connectorData.tariff.title}
          </p>
          <p className="leading-4 text-muted font-figtree text-xs">
            {connectorData.tariff.description}
          </p>
        </div>
        <div className="text-muted text-xl ml-8">
          {rateMoney.toFormat('0.00')} {rateMoney.getCurrency()}
        </div>
        <div className="ml-12 text-sm flex items-center">
          {t('CONNECTOR_PAGE.VAT')}
          <span className="ml-6 text-xl text-muted">{connectorData.tariff.vatPercent}%</span>
        </div>
        <div className="ml-20 text-sm flex items-center">
          {t('CONNECTOR_PAGE.CONNECTORS_COUNT')}
          <span className="ml-6 text-xl text-muted">{connectorData.countConnectorsByTariff}</span>
        </div>
        <img src={ArrowIcon} alt="arrow right icon" className="p-3 ml-auto mr-2.5" />
      </TableRow>
    </PageLayout>
  );
};
