import { useQuery } from '@tanstack/react-query';

import { getCpmsChargeTransaction } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';

export const useChargeTransactionData = (id: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_TRANSACTION, id],
    queryFn: () => getCpmsChargeTransaction({ path: { id } }).then(({ data }) => data),
  });
};
