import { zodResolver } from '@hookform/resolvers/zod';
import { fromKiloWattToWatt, isDefined } from '@ion-hq/utils';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { EditFormLayout } from '@/common/root/edit-form-layout';
import { PageLayout } from '@/common/root/page-layout';
import { ChargePointEditForm } from '@/components/charge-point-edit-form';
import { Spinner } from '@/components/spinner';
import { Button } from '@/shadcn/ui/button';

import {
  editChargePointFormSchema,
  getDefaultConnector,
} from '../charge-point/edit-charge-point-page/utils';
import { useChargeSiteData } from '../charge-site/hooks/use-charge-site-data.query';
import { useAddChargePointsAndConnectorsMutation } from './hooks/add-charge-points-and-connectors.mutation';

const arrayOfEditChargePointFormSchema = z.object({
  chargePoints: z.array(editChargePointFormSchema),
});

export type ArrayEditChargePointFormType = z.infer<typeof arrayOfEditChargePointFormSchema>;

export const AddChargePointsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chargeSiteId } = useParams();
  const { data: chargeSite, isLoading } = useChargeSiteData(Number(chargeSiteId));
  const { mutateAsync, isPending } = useAddChargePointsAndConnectorsMutation();

  const formHandlers = useForm<ArrayEditChargePointFormType>({
    defaultValues: {
      chargePoints: [
        {
          id: null,
          connectors: [getDefaultConnector()],
        },
      ],
    },
    resolver: zodResolver(arrayOfEditChargePointFormSchema),
  });

  const { fields, append, remove } = useFieldArray<ArrayEditChargePointFormType>({
    name: 'chargePoints',
    rules: { minLength: 1 },
    control: formHandlers.control,
  });

  if (isLoading || !chargeSiteId) {
    return <Spinner className="h-full" />;
  }

  const handleSubmit = formHandlers.handleSubmit(async (data) => {
    await mutateAsync({
      chargeSiteId: Number(chargeSiteId),
      data: {
        chargePoints: data.chargePoints.map((chargePoint) => ({
          connectors: chargePoint.connectors.map((connector) => ({
            ...connector,
            powerWt: fromKiloWattToWatt(connector.powerWt),
          })),
        })),
      },
    });
    navigate(`/locations/${chargeSiteId}`);
  });

  const handleAddChargePoint = () => append({ id: null, connectors: [getDefaultConnector()] });

  const lastChargePoint = chargeSite?.chargePoints.at(-1);
  const chargerReadableIdStartIndex =
    isDefined(lastChargePoint?.readableId) && Number.isFinite(Number(lastChargePoint.readableId))
      ? Number(lastChargePoint.readableId)
      : 0;

  return (
    <PageLayout title={t('ADD_CHARGE_POINTS_PAGE.PAGE_NAME')}>
      <EditFormLayout>
        <FormProvider {...formHandlers}>
          <form onSubmit={handleSubmit}>
            {fields.map((field, chargePointIndex) => {
              const chargePointReadableId = chargerReadableIdStartIndex + chargePointIndex + 1;

              return (
                <ChargePointEditForm
                  key={field.id}
                  chargePointIndex={chargePointIndex}
                  chargePointReadableId={chargePointReadableId}
                  deleteChargePoint={() => remove(chargePointIndex)}
                  archivedConnectorsIds={[]}
                />
              );
            })}

            <Button
              type="button"
              size="fullWidth"
              variant="secondary"
              onClick={handleAddChargePoint}
            >
              {t('ADD_CHARGE_POINTS_PAGE.ADD_CHARGE_POINT')}
            </Button>

            <div className="fixed bottom-0 bg-background-muted left-52 right-4 flex justify-between p-6">
              <Link to={`/locations/${chargeSiteId}`}>
                <Button variant="outline" type="button">
                  {t('CANCEL')}
                </Button>
              </Link>

              <Button type="submit" isLoading={isPending}>
                {t('SAVE')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </EditFormLayout>
    </PageLayout>
  );
};
