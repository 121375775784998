import { forwardRef, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { cn } from '@/lib/utils';

const Table = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div className={cn('relative overflow-hidden', className)} ref={ref} {...props} />
  ),
);

Table.displayName = 'Table';

const TableHeader = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ title: ReactNode; className?: string }>
>(({ className, title, ...props }, ref) => (
  <div>
    <div
      ref={ref}
      className={cn(
        'flex items-center h-14 font-semibold px-6 bg-background-muted rounded-t-md',
        className,
      )}
      {...props}
    >
      {title}
    </div>
    <TableRow className="bg-background font-figtree font-normal text-muted h-10 border-b-1.5 border-background-muted">
      {props.children}
    </TableRow>
  </div>
));

TableHeader.displayName = 'TableHearder';

const TableRow = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center h-14', className)} {...props} />
  ),
);

TableRow.displayName = 'TableRow';

export { Table, TableRow, TableHeader };
