import { useTranslation } from 'react-i18next';

import TrashCanIcon from '@/assets/icons/trash-can.svg?react';
import { Button } from '@/shadcn/ui/button';

import { Icon } from './icon';
import { Modal } from './modal';

type Props = {
  connectorReadableId: string;
  connectorIndex: number;
  remove: () => void;
};

export const DeleteConnectorModal = ({ connectorReadableId, connectorIndex, remove }: Props) => {
  const { t } = useTranslation();

  const shouldShowDeleteButton = connectorIndex !== 0;

  return (
    <Modal
      title={t('ADD_CHARGE_POINTS_PAGE.DELETE_CONNECTOR_MODAL_TITLE', {
        connectorReadableId,
      })}
      description={t('ADD_CHARGE_POINTS_PAGE.DELETE_CONNECTOR_MODAL_DESCRIPTION', {
        connectorReadableId,
      })}
      trigger={
        shouldShowDeleteButton && (
          <Button
            type="button"
            variant="outline"
            className="h-14 w-14 border-primary bg-transparent text-primary"
          >
            <Icon reactIcon={<TrashCanIcon />} className="scale-75" />
          </Button>
        )
      }
      renderConfirmButton={(close) => (
        <Button
          type="button"
          onClick={() => {
            remove();
            close();
          }}
        >
          {t('ADD_CHARGE_POINTS_PAGE.DELETE')}
        </Button>
      )}
    />
  );
};
