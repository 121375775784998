import { type ClassValue, clsx } from 'clsx';
import { format } from 'date-fns';
import isObject from 'lodash/isObject';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const cc = (condition: boolean, className: string): string =>
  condition ? ` ${className}` : '';

export const formatDate = (date: Date) => format(date, 'dd.MM.yyyy');
export const formatTime = (date: Date) => format(date, 'HH:mm');

export const formatDuration = (seconds: number) => {
  const formatedSeconds = (seconds % 60).toString().padStart(2, '0');
  const formatedMinutes = (Math.floor(seconds / 60) % 60).toString().padStart(2, '0');
  const formatedHours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');

  return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`;
};

export const isHttpError = (error: unknown) => isObject(error) && 'status' in error;
export const isUnauthorizedError = (error: unknown) => isHttpError(error) && error.status === 401;
export const isForbiddenError = (error: unknown) => isHttpError(error) && error.status === 403;
export const is404Error = (error: unknown) => isHttpError(error) && error.status === 404;
export const isInternalServerError = (error: unknown) => isHttpError(error) && error.status === 500;
