import { useMutation } from '@tanstack/react-query';

import {
  addChargePointsAndConnectors,
  CpmsAddChargePointsAndConnectorsRequestDto,
} from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';
import { queryClient } from '@/tanstack/use-query.setup.ts';

type Variables = {
  data: CpmsAddChargePointsAndConnectorsRequestDto;
  chargeSiteId: number;
};

export const useAddChargePointsAndConnectorsMutation = () =>
  useMutation({
    mutationFn: ({ data, chargeSiteId }: Variables) =>
      addChargePointsAndConnectors({ body: data, path: { id: chargeSiteId } }).then(
        ({ data }) => data,
      ),

    onSettled: async (_, __, { chargeSiteId }) => {
      queryClient.refetchQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
        type: 'inactive',
      });
    },
  });
