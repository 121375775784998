import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArchiveIcon from '@/assets/icons/archive.svg?react';
import { Icon } from '@/components/icon';
import { Modal } from '@/components/modal';
import { SettingsMenu } from '@/components/settings-menu';
import { CpmsConnectorResponseDto } from '@/open-api/_api';
import { Button } from '@/shadcn/ui/button';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';

import { useDeleteConnectorMutation } from './hooks/delete-connector.mutation';

type Props = {
  connector: CpmsConnectorResponseDto;
};

export const ConnectorSettingsMenu = ({ connector }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: deleteConnector, isPending } = useDeleteConnectorMutation();

  const handleDelete = async () => {
    await deleteConnector({
      chargeSiteId: connector.chargeSite.id,
      chargePointId: connector.chargePointId,
      connectorId: connector.id,
    });
    navigate(`/connectors/${connector.id}`);
  };

  return (
    <SettingsMenu title={t('CONNECTOR_PAGE.SETTINGS')}>
      <Modal
        title={t('CONNECTOR_PAGE.ARCHIVE_CONNECTOR')}
        subtitle={t('CONNECTOR_PAGE.ARCHIVE_MODAL_SUBTITLE', { connector })}
        variant="destructive"
        trigger={
          <DropdownMenuItem className="cursor-pointer" onSelect={(event) => event.preventDefault()}>
            <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
            {t('CONNECTOR_PAGE.ARCHIVE_CONNECTOR')}
          </DropdownMenuItem>
        }
        description={<div>{t('CONNECTOR_PAGE.ARCHIVE_MODAL_DESCRIPTION', { connector })}</div>}
        renderConfirmButton={(close) => (
          <Button
            className="min-w-48"
            isLoading={isPending}
            onClick={async () => {
              await handleDelete();
              close();
            }}
          >
            {t('ARCHIVE')}
          </Button>
        )}
      />
    </SettingsMenu>
  );
};
