import { z } from 'zod';

import { CONNECTOR_TYPES } from '@/core.const';

export const editChargePointFormSchema = z.object({
  id: z.number().positive().int().nullable(),
  connectors: z.array(
    z.object({
      id: z.number().positive().int().nullable(),
      powerWt: z.number().positive().int(),
      type: z.nativeEnum(CONNECTOR_TYPES),
      tariffId: z.number(),
    }),
  ),
});

export const getDefaultConnector = () => ({ id: null, powerWt: 0, type: 1, tariffId: 1 }) as const;

export type EditChargePointFormType = z.infer<typeof editChargePointFormSchema>;
