import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ArchiveIcon from '@/assets/icons/archive.svg?react';
import EditIcon from '@/assets/icons/edit.svg?react';
import { Icon } from '@/components/icon';
import { Modal } from '@/components/modal';
import { SettingsMenu } from '@/components/settings-menu';
import { CpmsChargePointResponseDto } from '@/open-api/_api';
import { Button } from '@/shadcn/ui/button';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';

import { useDeleteChargePointMutation } from '../hooks/delete-charge-point.mutation';
import { useRestoreChargePointMutation } from '../hooks/restore-charge-point.mutation';

type Props = {
  chargePoint: CpmsChargePointResponseDto;
};

export const ChargePointSettingsMenu = ({ chargePoint }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteChargePoint, isPending: isPendingDelete } =
    useDeleteChargePointMutation();
  const { mutateAsync: restoreChargePoint, isPending: isPendingRestore } =
    useRestoreChargePointMutation();

  const handleDelete = async () => {
    await deleteChargePoint({
      chargeSiteId: chargePoint.chargeSite.id,
      chargePointId: chargePoint.id,
    });
  };

  const handleRestore = async () => {
    await restoreChargePoint({
      chargeSiteId: chargePoint.chargeSite.id,
      chargePointId: chargePoint.id,
      connectorIds: chargePoint.connectors.map((connector) => connector.id),
      refetch: 'none',
    });
  };

  return (
    <SettingsMenu title="Charger Settings">
      {chargePoint.isArchived ? (
        <Modal
          title={t('CHARGE_POINT_PAGE.UNARCHIVE_CHARGE_POINT')}
          subtitle={chargePoint.chargeSite.title}
          variant="default"
          trigger={
            <DropdownMenuItem
              className="cursor-pointer"
              onSelect={(event) => event.preventDefault()}
            >
              <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
              {t('CHARGE_POINT_PAGE.UNARCHIVE_CHARGE_POINT')}
            </DropdownMenuItem>
          }
          description={t('CHARGE_POINT_PAGE.UNARCHIVE_CHARGE_POINT_MODAL_DESCRIPTION', {
            chargePoint,
          })}
          renderConfirmButton={(close) => (
            <Button
              className="min-w-48"
              isLoading={isPendingRestore}
              onClick={async () => {
                await handleRestore();
                close();
              }}
            >
              {t('UNARCHIVE')}
            </Button>
          )}
        />
      ) : (
        <>
          <DropdownMenuItem asChild className="cursor-pointer">
            <Link to={`/chargers/${chargePoint.id}/edit`}>
              <Icon reactIcon={<EditIcon />} className="mr-2" />
              {t('CHARGE_POINT_PAGE.EDIT_CHARGE_POINT')}
            </Link>
          </DropdownMenuItem>
          <Modal
            title={t('CHARGE_POINT_PAGE.ARCHIVE_CHARGE_POINT')}
            subtitle={t('CHARGE_POINT_PAGE.ARCHIVE_MODAL_SUBTITLE', { chargePoint })}
            variant="destructive"
            trigger={
              <DropdownMenuItem
                className="cursor-pointer"
                onSelect={(event) => event.preventDefault()}
              >
                <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
                {t('CHARGE_POINT_PAGE.ARCHIVE_CHARGE_POINT')}
              </DropdownMenuItem>
            }
            description={t('CHARGE_POINT_PAGE.ARCHIVE_MODAL_DESCRIPTION', { chargePoint })}
            renderConfirmButton={(close) => (
              <Button
                className="min-w-48"
                isLoading={isPendingDelete}
                onClick={async () => {
                  await handleDelete();
                  close();
                }}
              >
                {t('ARCHIVE')}
              </Button>
            )}
          />
        </>
      )}
    </SettingsMenu>
  );
};
