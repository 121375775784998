import { useQuery } from '@tanstack/react-query';

import { getCpmsChargeSiteList, GetCpmsChargeSiteListData } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

export const useChargeSiteList = (query: GetCpmsChargeSiteListData['query']) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_SITE_LIST, query?.page],

    queryFn: () => getCpmsChargeSiteList({ query }).then(({ data }) => data),
  });
};
