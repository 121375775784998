import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

export const EditFormLayout = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={cn(
        'h-full [&>form]:overflow-auto [&>form]:relative [&>form]:h-full [&>form]:bg-background-muted [&>form]:-mx-6 [&>form]:p-6 [&>form]:pb-28 [&>form]:grow',
      )}
    >
      {children}
    </div>
  );
};
