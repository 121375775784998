import { fromWattToKiloWatt, isDefined, Money } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import BarcodeIcon from '@/assets/icons/barcode.svg?react';
import CalendarIcon from '@/assets/icons/calendar.svg?react';
import ClockIcon from '@/assets/icons/clock.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import PlugIcon from '@/assets/icons/plug.svg?react';
import UserIcon from '@/assets/icons/user.svg?react';
import { PageLayout } from '@/common/root/page-layout';
import { Icon } from '@/components/icon';
import { LocationMarker } from '@/components/leaflet/location-marker';
import { MapComponent } from '@/components/leaflet/map';
import { Widget } from '@/components/widget';
import { formatDate, formatDuration, formatTime } from '@/lib/utils';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';

import { useChargeTransactionData } from '../hooks/use-charge-transaction.query';
import { ChargeTransactionPageSkeleton } from './charge-transaction.skeleton';

export const ChargeTransactionPage = () => {
  const { t } = useTranslation();
  const { chargeTransactionId = '' } = useParams();
  const { data } = useChargeTransactionData(Number(chargeTransactionId));

  if (!data) {
    return <ChargeTransactionPageSkeleton />;
  }

  const payment = isDefined(data.calculatedPayment)
    ? Money.fromPair(data.calculatedPayment, data.tariff.currency)
    : null;

  const vat = isDefined(data.vatAmount)
    ? Money.fromPair(data.vatAmount, data.tariff.currency)
    : null;

  return (
    <PageLayout
      title={t('CHARGE_TRANSACTION_PAGE.PAGE_NAME', { chargeTransactionId })}
      backUrl={'/sessions'}
    >
      <div className="flex gap-4">
        <div className="w-1/2">
          <div className="flex gap-4 mb-4">
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.DATE')}
              content={data.chargingEndedAt ? formatDate(data.chargingEndedAt) : ''}
              fullWidth
              contentProps={{ size: '2xl' }}
              reactIcon={<Icon reactIcon={<CalendarIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.TIME')}
              content={data.chargingEndedAt ? formatTime(data.chargingEndedAt) : ''}
              fullWidth
              contentProps={{ size: '2xl' }}
              reactIcon={<Icon reactIcon={<ClockIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.USER')}
              content={data.user.phoneNumber ?? 'N/A'}
              fullWidth
              reactIcon={<Icon reactIcon={<UserIcon />} />}
            />
          </div>
          <div className="flex gap-4 mb-4">
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.SESSION_ID')}
              content={data.id}
              fullWidth
              reactIcon={<Icon reactIcon={<BarcodeIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.CHARGE_SITE')}
              content={data.chargePoint.chargeSite.title}
              fullWidth
              reactIcon={<Icon reactIcon={<HomeIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.CONNECTOR_UNIQ_ID')}
              content={data.connector.uniqId}
              fullWidth
              reactIcon={<Icon reactIcon={<PlugIcon />} />}
            />
          </div>
          <Table className="mb-4 border-1.5 border-background-muted rounded-md">
            <TableHeader title={t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.TITLE')}>
              <div className="text-xs ml-6 w-10">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.TIME')}
              </div>
              <div className="text-xs ml-16 w-20">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.DURATION')}
              </div>
              <div className="text-xs ml-10 w-20">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.ENERGY')}
              </div>
              <div className="text-xs ml-auto w-20">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.TARIFF_RATE')}
              </div>
              <div className="text-xs mx-10 w-24">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.SESSION_COST')}
              </div>
            </TableHeader>
            <TableRow className="h-16">
              <div className="ml-6 w-10 font-figtree">
                {data.chargingEndedAt ? formatTime(data.chargingEndedAt) : ''}
              </div>
              <div className="ml-16 w-20 font-figtree">
                {data.sessionDurationSec ? formatDuration(data.sessionDurationSec) : null}
              </div>
              <div className="ml-10 w-20 text-xl font-medium">
                {data.finalWhUsed ? fromWattToKiloWatt(data.finalWhUsed) : null}
              </div>
              <div className="ml-auto w-20 text-xl font-medium">
                {data.tariff.ratePerWh} {data.tariff.currency}
              </div>
              <div className="mx-10 w-24 text-xl font-medium">
                {payment?.toFormat('0.00')} {payment?.getCurrency()}
              </div>
            </TableRow>
          </Table>
          <Table className="border-1.5 border-background-muted rounded-md">
            <TableHeader title={t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.TITLE')}>
              <div className="text-xs ml-6 w-32">
                {t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.HEADERS.METHOD')}
              </div>
              <div className="text-xs ml-auto w-20">
                {t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.HEADERS.VAT', {
                  vatPercent: data.tariff.vatPercent,
                })}
              </div>
              <div className="text-xs mx-10 w-24">
                {t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.HEADERS.TOTAL_AMOUNT')}
              </div>
            </TableHeader>
            <TableRow className="h-16">
              <div className="ml-6 w-32 font-medium text-xl">{data.paymentMethodDescription}</div>
              <div className="ml-auto w-20 text-xl font-medium">
                {vat?.toFormat('0.00')} {vat?.getCurrency()}
              </div>
              <div className="mx-10 w-24 text-xl font-medium">
                {payment?.toFormat('0.00')} {payment?.getCurrency()}
              </div>
            </TableRow>
          </Table>
        </div>
        <div className="w-1/2">
          <MapComponent>
            <LocationMarker
              position={{
                lat: data.chargePoint.chargeSite.latitude,
                lng: data.chargePoint.chargeSite.longitude,
              }}
            />
          </MapComponent>
        </div>
      </div>
    </PageLayout>
  );
};
