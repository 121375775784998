import { fromWattToKiloWatt } from '@ion-hq/utils';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Fragment } from 'react/jsx-runtime';
import { Link, useParams } from 'react-router-dom';

import BarcodeIcon from '@/assets/icons/barcode.svg?react';
import GeolocationIcon from '@/assets/icons/geolocation.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import PowerButtonIcon from '@/assets/icons/power-button.svg?react';
import { PageLayout } from '@/common/root/page-layout';
import { ConnectorIcon } from '@/components/connector-icon';
import { Icon } from '@/components/icon';
import { LocationMarker } from '@/components/leaflet/location-marker';
import { MapComponent } from '@/components/leaflet/map';
import { Modal } from '@/components/modal';
import { Widget } from '@/components/widget';
import { t } from '@/i18n/i18n';
import { CpmsChargePointResponseDto } from '@/open-api/_api';
import { useRestoreConnectorMutation } from '@/pages/connector/hooks/restore-connector.mutation';
import { Badge } from '@/shadcn/ui/badge';
import { Button } from '@/shadcn/ui/button';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';

import { getChargeSiteLatLng } from '../../charge-site/utils/charge-site.utils';
import { useChargePointData } from '../hooks/use-charge-point.query';
import { ChargePointPageSkeleton } from './charge-point-page-skeleton';
import { ChargePointSettingsMenu } from './charge-point-settings-menu';

const columns: ColumnDef<CpmsChargePointResponseDto['connectors'][number]>[] = [
  {
    accessorKey: 'readableId',
    header: () => (
      <span className="text-xs pl-4 w-20">{t('CONNECTOR_TABLE_HEADERS.READABLE_ID')}</span>
    ),
    cell: (info) => (
      <span className="text-lg font-semibold pl-8 w-20">{info.row.original.readableId}</span>
    ),
  },
  {
    accessorKey: 'uniqId',
    header: () => <span className="text-xs pl-3 w-48">{t('CONNECTOR_TABLE_HEADERS.UNIQ_ID')}</span>,
    cell: (info) => (
      <span className="font-figtree font-text-sm pl-4 w-48">
        <Link
          to={`/connectors/${info.row.original.id}`}
          className="hover:text-primary hover:underline"
        >
          {info.row.original.uniqId}{' '}
        </Link>
      </span>
    ),
  },
  {
    accessorKey: 'power',
    header: () => <span className="text-xs w-20">{t('CONNECTOR_TABLE_HEADERS.POWER')}</span>,
    cell: (info) => (
      <span className="font-medium text-lg w-20">
        {fromWattToKiloWatt(info.row.original.powerWt)}
      </span>
    ),
  },
  {
    accessorKey: 'type',
    header: () => (
      <span className="text-xs p-2 w-32">{t('CONNECTOR_TABLE_HEADERS.CONNECTOR_TYPE')}</span>
    ),
    cell: (info) => (
      <div className="flex gap-2 p-2 text-muted w-32">
        <ConnectorIcon connectorType={info.row.original.type} />
      </div>
    ),
  },
  {
    accessorKey: 'tariff',
    header: () => <span className="text-xs p-2">{t('CONNECTOR_TABLE_HEADERS.TARIFF')}</span>,
    cell: (info) => (
      <span className="ml-2 text-lg text-muted">{info.row.original.tariff.title}</span>
    ),
  },
  {
    accessorKey: 'status',
    header: () => (
      <span className="text-xs ml-auto mr-24">{t('CHARGE_SITES_PAGE.TABLE.HEADERS.STATUS')}</span>
    ),
    cell: (info) => {
      const isChargePointArchived = 'isChargePointArchived' in info && info.isChargePointArchived;

      return info.row.original.isArchived ? (
        <>
          <Badge className={`ml-auto ${isChargePointArchived ? 'mr-16' : ''}`} variant="ARCHIVED" />
          {!isChargePointArchived && (
            <Modal
              title={t('CHARGE_POINT_PAGE.UNARCHIVE_CONNECTOR')}
              subtitle={info.row.original.chargeSite.title}
              variant="default"
              trigger={<Icon reactIcon={<PowerButtonIcon />} className="p-6" />}
              description={t('CHARGE_POINT_PAGE.UNARCHIVE_CONNECTOR_MODAL_DESCRIPTION', {
                connector: info.row.original,
              })}
              renderConfirmButton={(close) => (
                <Button
                  className="min-w-48"
                  isLoading={info.table.options.meta?.isRestoreConnectorPending}
                  onClick={async () => {
                    await info.table.options.meta?.handleRestoreConnector?.(info.row.original);
                    close();
                  }}
                >
                  {t('UNARCHIVE')}
                </Button>
              )}
            />
          )}
        </>
      ) : null;
    },
  },
];

export const ChargePointPage = () => {
  const { chargePointId } = useParams();
  const { data: chargePoint, isLoading } = useChargePointData(Number(chargePointId));

  const { mutateAsync: restoreConnector, isPending: isRestoreConnectorPending } =
    useRestoreConnectorMutation();
  const handleRestoreConnector = (connector: CpmsChargePointResponseDto['connectors'][number]) => {
    restoreConnector({
      chargeSiteId: connector.chargeSite.id,
      chargePointId: connector.chargePointId,
      connectorId: connector.id,
    });
  };

  const table = useReactTable({
    data: chargePoint?.connectors ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: { handleRestoreConnector, isRestoreConnectorPending },
  });

  if (isLoading || !chargePoint) {
    return <ChargePointPageSkeleton />;
  }

  const chargeSiteLatLng = getChargeSiteLatLng(chargePoint.chargeSite);

  return (
    <PageLayout
      title={t('CHARGE_POINT_PAGE.PAGE_NAME', { readableId: chargePoint.readableId })}
      backUrl={`/locations/${chargePoint.chargeSite.id}`}
      rightSlot={
        <div className="flex gap-4">
          {chargePoint.isArchived && <Badge variant="ARCHIVED" className="mr-4" />}
          <ChargePointSettingsMenu chargePoint={chargePoint} />
        </div>
      }
    >
      <div className="flex mb-4">
        <div className="w-1/2 flex-col gap-4">
          <Widget
            title={t('CHARGE_POINT_PAGE.CHARGE_POINT_UNIQ_ID')}
            content={chargePoint.uniqId}
            contentProps={{ size: '2xl' }}
            reactIcon={<BarcodeIcon />}
          />
          <div className="flex mt-4 gap-4">
            <Widget
              title={t('CHARGE_POINT_PAGE.CHARGE_SITE')}
              content={chargePoint.chargeSite.title}
              fullWidth
              reactIcon={<HomeIcon />}
            />
            <Widget
              title={t('COORDINATES')}
              content={
                <div>
                  {chargePoint.chargeSite.latitude}
                  <br />
                  {chargePoint.chargeSite.longitude}
                </div>
              }
              fullWidth
              reactIcon={<GeolocationIcon />}
            />
          </div>
        </div>
        <div className="w-1/2 ml-4 z-0">
          <div className="h-full mb-4 ">
            <MapComponent center={chargeSiteLatLng}>
              <LocationMarker
                position={chargeSiteLatLng}
                isSelected
                tooltipContent={chargePoint.chargeSite.title}
              />
            </MapComponent>
          </div>
        </div>
      </div>
      <Table className="border-1.5 border-background-muted rounded-md">
        <TableHeader title={t('CHARGE_POINT_PAGE.TABLE_HEADER')}>
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Fragment>
              )),
            )}
        </TableHeader>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            className={
              'relative transition-colors hover:bg-primary-light after:content after:absolute after:mx-5 after:top-14 after:left-0 after:right-0 after:h-[1px] after:bg-background-muted'
            }
          >
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>
                {flexRender(cell.column.columnDef.cell, {
                  ...cell.getContext(),
                  isChargePointArchived: chargePoint.isArchived,
                })}
              </Fragment>
            ))}
          </TableRow>
        ))}
      </Table>
    </PageLayout>
  );
};
