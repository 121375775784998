import { ReactNode } from 'react';

import { isDefined } from '@ion-hq/utils';
import { Link } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow.svg';
import { Card, CardContent, CardTitle } from '@/shadcn/ui/card';

import { Icon } from './icon';

type Props = {
  title: string;
  linkTo?: string;
  /** Inline SVG icon imported with "?react" passed as JSX */
  reactIcon?: ReactNode;
  content?: ReactNode;
  contentProps?: {
    text?: 'text-foreground' | 'text-muted' | 'text-destructive';
    size?: keyof typeof sizeClassMap;
  };
  doubleHeightContent?: ReactNode;
  fullWidth?: boolean;
};

const sizeClassMap = {
  default: 'font-figtree',
  '2xl': 'text-2xl font-semibold font-figtree',
  '5xl': 'text-5xl',
};

export const Widget = ({
  title,
  linkTo,
  reactIcon,
  content,
  contentProps: { text = 'text-foreground', size = 'default' } = {},
  doubleHeightContent,
  fullWidth = false,
}: Props) => {
  let card = (
    <Card
      className={doubleHeightContent ? 'h-64' : 'h-full min-h-30 flex flex-col justify-between'}
    >
      <CardTitle className="p-4 font-figtree text-muted flex justify-between items-center">
        {title}
        {linkTo && <img src={ArrowIcon} alt="arrow icon" width={16} height={16} />}
      </CardTitle>
      {isDefined(content) && (
        <CardContent
          className={`p-4 flex justify-between items-end gap-6 ${text} ${sizeClassMap[size]}`}
        >
          {content}
          {reactIcon && <Icon className="text-muted" reactIcon={reactIcon} />}
        </CardContent>
      )}
      {doubleHeightContent && <div className="p-4 pt-6">{doubleHeightContent}</div>}
    </Card>
  );

  if (linkTo) {
    card = <Link to={linkTo}>{card}</Link>;
  }

  return <div className={fullWidth ? 'w-full' : 'min-w-52'}>{card}</div>;
};
