import { useMutation } from '@tanstack/react-query';

import { deleteConnector } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';
import { queryClient } from '@/tanstack/use-query.setup.ts';

type Variables = {
  chargeSiteId: number;
  chargePointId: number;
  connectorId: number;
};

export const useDeleteConnectorMutation = () => {
  return useMutation({
    mutationFn: ({ connectorId }: Variables) =>
      deleteConnector({ path: { id: connectorId } }).then(({ data }) => data),

    onSettled: (_, __, { connectorId, chargePointId, chargeSiteId }) => {
      queryClient.refetchQueries({
        queryKey: [QUERY_KEY.CONNECTOR_ITEM, connectorId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CHARGE_POINT_ITEM, chargePointId],
        type: 'inactive',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
        type: 'inactive',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
        type: 'inactive',
      });
    },
  });
};
