import { ChargeSitesTable } from '@/components/charge-sites-table';
import { CpmsChargeSiteListResponseDto } from '@/open-api/_api';

import { ChargeSitesEmpty } from '../components/charge-sites-empty';

type Props = {
  data: CpmsChargeSiteListResponseDto;
};

export const ChargeSiteListPageContent = ({ data }: Props) => {
  if (data.totalItems === 0) {
    return <ChargeSitesEmpty />;
  }
  const { items, ...pagination } = data;

  return <ChargeSitesTable data={items} pagination={pagination} />;
};
