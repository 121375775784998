import { ReactNode } from 'react';

import { getAuth } from 'firebase/auth';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from '@/components/icon';
import { t } from '@/i18n/i18n';
import { cc } from '@/lib/utils';
import { Avatar } from '@/shadcn/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shadcn/ui/dropdown-menu';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from '@/shadcn/ui/sidebar';

import AvatarIcon from '../../assets/icons/avatar.svg?react';
import DashboardIcon from '../../assets/icons/dashboard.svg?react';
import LocationsIcon from '../../assets/icons/locations.svg?react';
import LogoutIcon from '../../assets/icons/logout.svg?react';
import SessionsIcon from '../../assets/icons/sessions.svg?react';
import TariffsIcon from '../../assets/icons/tariffs.svg?react';
import UserIcon from '../../assets/icons/user.svg?react';
import IonLogo from '../../assets/logos/ion.svg';

type Props = {
  children: ReactNode;
};

const items = [
  {
    title: t('SIDEBAR.DASHBOARD'),
    url: '/',
    icon: () => <Icon reactIcon={<DashboardIcon />} />,
  },
  {
    title: t('SIDEBAR.CHARGE_SITES'),
    url: '/locations',
    icon: () => <Icon reactIcon={<LocationsIcon />} />,
  },
  {
    title: t('SIDEBAR.TARIFFS'),
    url: '/tariffs',
    icon: () => <Icon reactIcon={<TariffsIcon />} />,
  },
  {
    title: t('SIDEBAR.SESSIONS'),
    url: '/sessions',
    icon: () => <Icon reactIcon={<SessionsIcon />} />,
  },
  {
    title: t('SIDEBAR.USERS'),
    url: '/users',
    icon: () => <Icon reactIcon={<UserIcon />} />,
  },
];

export const SidebarLayout = ({ children }: Props) => {
  const location = useLocation();

  const isCurrentRoute = (url: string) =>
    (location.pathname.startsWith(url) && url !== items[0].url) || location.pathname === url;

  const handleLogout = () => getAuth().signOut();

  return (
    <SidebarProvider>
      <Sidebar variant="inset">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <SidebarHeader className="hover:text-primary">
              <Avatar>
                <Icon reactIcon={<AvatarIcon />} />
              </Avatar>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap font-medium">
                {getAuth().currentUser?.email ?? getAuth().currentUser?.phoneNumber ?? 'User'}
              </span>
            </SidebarHeader>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem className="w-10/12" onSelect={handleLogout}>
              <Icon reactIcon={<LogoutIcon />} className="mr-2" />
              {t('LOGOUT')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <SidebarContent>
          <SidebarMenu>
            {items.map((item) => (
              <SidebarMenuItem
                key={item.title}
                className={cc(isCurrentRoute(item.url), 'bg-black text-background')}
              >
                <SidebarMenuButton asChild>
                  <Link to={item.url}>
                    <item.icon />
                    <span className="leading-4">{item.title}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarContent>
        <SidebarFooter>
          <img src={IonLogo} alt="ION logo" width={32} height={32} />
        </SidebarFooter>
      </Sidebar>
      <SidebarInset>{children}</SidebarInset>
    </SidebarProvider>
  );
};
