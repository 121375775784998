import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';

import { PaginationProperties } from '@/common.types';
import { t } from '@/i18n/i18n';
import { CpmsChargeSiteListResponseDto } from '@/open-api/_api';
import { usePagination } from '@/pagination/pagination.hooks';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shadcn/ui/accordion';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';

import { Pagination } from '../pagination/pagination';
import { ChargePointsTable } from './charge-points-table';

type ChargeSite = CpmsChargeSiteListResponseDto['items'][number];

const columns: ColumnDef<ChargeSite>[] = [
  {
    accessorKey: 'title',
    header: () => (
      <span className="w-40 pl-6 text-xs">{t('CHARGE_SITES_PAGE.TABLE.HEADERS.CHARGE_SITE')}</span>
    ),
    cell: (info) => {
      return (
        <div className="p-2 pl-6 w-96 text-left">
          <p className="font-medium text-lg leading-7">
            <Link
              to={`/locations/${info.row.original.id}`}
              className="hover:underline hover:text-primary"
            >
              {info.row.original.title}
            </Link>
          </p>
          <p className="leading-4 text-muted text-xs">{info.row.original.address}</p>
        </div>
      );
    },
  },
  {
    accessorKey: 'chargePointsCount',
    header: () => (
      <span className="text-xs ml-80">{t('CHARGE_SITES_PAGE.TABLE.HEADERS.CHARGE_POINTS')}</span>
    ),
    cell: (info) => {
      const chargePointsCount = info.row.original.activeChargePointsCount;

      return <p className="text-lg text-muted p-4 ml-20">{chargePointsCount}</p>;
    },
  },
  {
    accessorKey: 'status',
    header: () => (
      <span className="text-xs ml-auto mr-24">{t('CHARGE_SITES_PAGE.TABLE.HEADERS.STATUS')}</span>
    ),
  },
];

type Props = {
  data: ChargeSite[];
  pagination: PaginationProperties;
};

export const ChargeSitesTable = ({ data, pagination }: Props) => {
  const { setPage } = usePagination();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Accordion type="single" collapsible className="h-[calc(100%-144px)] pb-6">
      <Table className="rounded-md border-1.5 border-background-muted h-full">
        <TableHeader title={t('CHARGE_SITES_PAGE.TABLE.TITLE')}>
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Fragment>
              )),
            )}
        </TableHeader>
        <div className="h-[calc(100%-136px)] overflow-y-auto">
          {table.getRowModel().rows.map((row) => {
            const cells = row
              .getVisibleCells()
              .map((cell) => (
                <Fragment key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Fragment>
              ));

            return (
              <AccordionItem
                value={row.id}
                key={row.id}
                className="relative transition-colors hover:bg-primary-light data-[state=open]:bg-background-muted after:content after:absolute after:bg-background-muted after:mx-5 after:top-14 after:left-0 after:right-0 after:h-[1px] data-[state=open]:after:bg-background"
              >
                <TableRow>
                  {row.original.chargePoints.length > 0 ? (
                    <AccordionTrigger>{cells}</AccordionTrigger>
                  ) : (
                    <div className="w-full flex items-center p-2">{cells}</div>
                  )}
                </TableRow>
                <AccordionContent>
                  <ChargePointsTable data={row.original.chargePoints} isStandalone={false} />
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </div>
        <TableRow className="h-10">
          <Pagination pagination={pagination} onSetPage={setPage} />
        </TableRow>
      </Table>
    </Accordion>
  );
};
