import { useMutation } from '@tanstack/react-query';

import { restoreChargePoint } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';
import { queryClient } from '@/tanstack/use-query.setup.ts';

type Variables = {
  chargeSiteId: number;
  chargePointId: number;
  connectorIds: number[];
  refetch: 'chargeSite' | 'chargeSiteList' | 'none';
};

export const useRestoreChargePointMutation = () => {
  return useMutation({
    mutationFn: ({ chargePointId }: Variables) =>
      restoreChargePoint({ path: { id: chargePointId } }).then(({ data }) => data),

    onSettled: (_, __, { chargeSiteId, chargePointId, connectorIds, refetch }) => {
      switch (refetch) {
        case 'chargeSite': {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
            type: 'inactive',
          });

          break;
        }

        case 'chargeSiteList': {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
            type: 'inactive',
          });

          break;
        }

        case 'none': {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
            type: 'inactive',
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
            type: 'inactive',
          });

          break;
        }
      }
      queryClient.refetchQueries({
        queryKey: [QUERY_KEY.CHARGE_POINT_ITEM, chargePointId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_STATISTIC],
        type: 'inactive',
      });
      for (const connectorId of connectorIds) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.CONNECTOR_ITEM, connectorId],
          type: 'inactive',
        });
      }
    },
  });
};
