import { t } from 'i18next';

import { Button } from '@/shadcn/ui/button';

import { Modal } from './modal';

type Props = {
  chargePointReadableId: number;
  deleteChargePoint: () => void;
};

export const DeleteChargePointModal = ({ chargePointReadableId, deleteChargePoint }: Props) => {
  return (
    <Modal
      title={t('ADD_CHARGE_POINTS_PAGE.DELETE_CHARGE_POINT_MODAL_TITLE', {
        chargePointReadableId,
      })}
      description={t('ADD_CHARGE_POINTS_PAGE.DELETE_CHARGE_POINT_MODAL_DESCRIPTION', {
        chargePointReadableId,
      })}
      trigger={
        <Button type="button" variant="textDestructive">
          {t('CHARGE_POINT_FORM.DELETE_CHARGE_POINT')}
        </Button>
      }
      renderConfirmButton={(close) => (
        <Button
          type="button"
          onClick={() => {
            deleteChargePoint();
            close();
          }}
        >
          {t('ADD_CHARGE_POINTS_PAGE.DELETE')}
        </Button>
      )}
    />
  );
};
