import { useTranslation } from 'react-i18next';

import { PageLayout } from '@/common/root/page-layout';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const ChargeTransactionPageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <PageLayout title={t('SKELETON.LOADING')} backUrl={'/sessions'}>
      <div className="flex gap-4">
        <div className="w-1/2">
          <div className="flex gap-4 mb-4">
            <Skeleton height="widget" />
            <Skeleton height="widget" />
            <Skeleton height="widget" />
          </div>
          <div className="flex gap-4 mb-4">
            <Skeleton height="widget" gradientOrigin="right" />
            <Skeleton height="widget" gradientOrigin="right" />
            <Skeleton height="widget" gradientOrigin="right" />
          </div>
          <Skeleton height="tableRow" />
          <Skeleton height="tableRow" gradientOrigin="center" className="mt-4" />
        </div>
        <div className="w-1/2">
          <Skeleton />
        </div>
      </div>
    </PageLayout>
  );
};
