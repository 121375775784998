import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { t } from 'i18next';
import { Fragment } from 'react/jsx-runtime';

import LightningIconSvg from '@/assets/images/lightning.svg';
import { CpmsChargeTransactionListResponseDto } from '@/open-api/_api';
import { Pagination } from '@/pagination/pagination';
import { usePagination } from '@/pagination/pagination.hooks';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';

import { chargeTransactionsListColumns } from './charge-transactions-list-columns';

type Props = {
  data: CpmsChargeTransactionListResponseDto;
};

export const ChargeTransactionsPageContent = ({ data }: Props) => {
  const { setPage } = usePagination();
  const table = useReactTable({
    data: data.items,
    columns: chargeTransactionsListColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (data.totalItems === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full my-16 gap-6 text-primary font-semibold">
        <img src={LightningIconSvg} alt="Lightning" />
        {t('CHARGE_TRANSACTIONS_PAGE.EMPTY')}
      </div>
    );
  }

  const { items, ...pagination } = data;

  return (
    <Table className="rounded-md border-1.5 border-background-muted h-full mb-6 w-full">
      <TableHeader title={t('CHARGE_TRANSACTIONS_PAGE.TABLE.TITLE')}>
        {table
          .getHeaderGroups()
          .map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <Fragment key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </Fragment>
            )),
          )}
      </TableHeader>
      <div className="h-[calc(100%-136px)] overflow-y-auto">
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Fragment>
            ))}
          </TableRow>
        ))}
      </div>
      <TableRow className="h-10">
        <Pagination pagination={pagination} onSetPage={setPage} />
      </TableRow>
    </Table>
  );
};
