import { zodResolver } from '@hookform/resolvers/zod';
import { fromKiloWattToWatt, fromWattToKiloWatt, isDefined } from '@ion-hq/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { EditFormLayout } from '@/common/root/edit-form-layout';
import { PageLayout } from '@/common/root/page-layout';
import { ChargePointEditForm } from '@/components/charge-point-edit-form';
import { Spinner } from '@/components/spinner';
import { Button } from '@/shadcn/ui/button';

import { useEditChargePointMutation } from '../hooks/edit-charge-point-mutation';
import { useChargePointData } from '../hooks/use-charge-point.query';
import { useTariffsData } from '../hooks/use-tariffs.query';
import { editChargePointFormSchema, EditChargePointFormType } from './utils';

export const EditChargePointPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chargePointId } = useParams();
  const { isLoading: isLoadingTariffs } = useTariffsData();
  const { data: chargePoint, isLoading: isLoadingChargePoint } = useChargePointData(
    Number(chargePointId),
  );
  const { mutateAsync, isPending } = useEditChargePointMutation();

  const formHandlers = useForm<EditChargePointFormType>({
    values: {
      id: chargePoint?.id ?? 0,
      connectors: (chargePoint?.connectors ?? []).map((connector) => ({
        id: connector.id,
        powerWt: Number(fromWattToKiloWatt(connector.powerWt)),
        type: connector.type,
        tariffId: connector.tariff.id,
      })),
    },
    resolver: zodResolver(editChargePointFormSchema),
  });

  if (isLoadingChargePoint || isLoadingTariffs || !isDefined(chargePoint)) {
    return <Spinner className="h-full" />;
  }

  const handleSubmit = formHandlers.handleSubmit(async (data) => {
    await mutateAsync({
      data: data.connectors.map((connector) => ({
        ...connector,
        powerWt: fromKiloWattToWatt(connector.powerWt),
      })),
      chargePointId: chargePoint.id,
      chargeSiteId: chargePoint.chargeSite.id,
    });

    navigate(`/chargers/${chargePointId}`);
  });

  const archivedConnectorsIds = chargePoint.connectors
    .filter((connector) => connector.isArchived)
    .map((connector) => connector.id);

  return (
    <PageLayout
      title={t('EDIT_CHARGE_POINT_PAGE.PAGE_NAME', { readableId: chargePoint.readableId })}
    >
      <EditFormLayout>
        <FormProvider {...formHandlers}>
          <form onSubmit={handleSubmit}>
            <ChargePointEditForm
              chargePointReadableId={Number(chargePoint.readableId)}
              archivedConnectorsIds={archivedConnectorsIds}
            />

            <div className="fixed bottom-0 bg-background-muted left-52 right-4 flex justify-between p-6">
              <Link to={`/chargers/${chargePointId}`}>
                <Button variant="outline" type="button">
                  {t('CANCEL')}
                </Button>
              </Link>

              <Button type="submit" isLoading={isPending}>
                {t('SAVE')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </EditFormLayout>
    </PageLayout>
  );
};
